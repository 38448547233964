import { BatteryLevel } from '../components/BatteryLevel';
import { GuardMeListFilter } from '../components/GuardMeListFilter';
import { GuardMeStatus } from '../components/GuardMeStatus';
import { useGuardMeTableControls } from '../hooks/useGuardMeTableControls';
import { DateRangePicker } from '@/components/DateRangePicker/DateRangePicker';
import { Datetime } from '@/components/Datetime';
import { TableSearch } from '@/components/TableSearch';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  TablePagnination,
  TableRowSkeleton,
} from '@/components/ui/TableWrapper';
import { MutationError } from '@/features/Misc';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { OrganizationNotFoundError } from '@/utils';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const GuardMesGql = graphql(`
  query GuardMesGql(
    $first: Int!
    $offset: Int!
    $organizationId: String!
    $shortId: String
    $status: GuardMesStatusConditionInput
    $createdAt_gte: Datetime
    $createdAt_lte: Datetime
    $memberId: String
  ) {
    guardmes(
      first: $first
      offset: $offset
      condition: {
        memberId: $memberId
        createdAt: { gte: $createdAt_gte, lte: $createdAt_lte }
        organizationId: $organizationId
        shortId: $shortId
        status: $status
      }
      orderBy: ID_DESC
    ) {
      totalCount
      nodes {
        id
        shortId
        status
        isSafe
        batteryLevel
        createdAt
        completedAt
        endsAt
        isActive
        isExpired
        guardmeAttachments {
          totalCount
        }
        memberId
        member {
          id
          fullName
          displayName
          avatarUrl
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`);

const GuardMes = () => {
  const [organizationId, memberId] = useAppStore((state) => [
    state.activeMembership?.id,
    state.activeMembership?.memberId,
  ]);

  if (!organizationId || !memberId) {
    throw new OrganizationNotFoundError();
  }

  const navigate = useNavigate();

  const controls = useGuardMeTableControls();

  const [{ data, error, fetching }] = useQuery({
    query: GuardMesGql,
    requestPolicy: 'cache-and-network',
    variables: {
      createdAt_gte: controls.from?.toISOString(),
      createdAt_lte: controls.to?.toISOString(),
      first: controls.pageSize,
      memberId: undefined,
      offset: controls.offset,
      organizationId,
      shortId: controls.search,
      status: controls.hasFilter
        ? {
            concluded: controls.concluded,
            expired: controls.expired,
            inPanic: controls.inPanic,
            inProgress: controls.inProgress,
          }
        : null,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/guardme/${entityId}`);
  };

  return (
    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div>
        <div className="flex items-center">
          <TableSearch
            className="h-8"
            placeholder="Search by Id..."
            setSearchValue={controls.setSearch}
          />
          <div className="ml-auto flex items-center gap-2">
            <GuardMeListFilter className="h-8" />
            <DateRangePicker
              className="h-8"
              dateRange={controls.dateRange}
              setDateRange={controls.setDateRange}
            />
          </div>
        </div>

        <div className="mt-2">
          <Card x-chunk="guardme-sessions">
            <CardHeader>
              <CardTitle>GuardMe Sessions</CardTitle>
              <CardDescription>
                Manage your products and view their sales performance.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow className="hover:bg-inherit">
                    <TableHead>Id</TableHead>
                    <TableHead>Member</TableHead>
                    <TableHead>Battery</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Created</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <MutationError error={error} />
                  {fetching && (
                    <TableRowSkeleton
                      cols={5}
                      rows={controls.pageSize / 3}
                    />
                  )}
                  {!fetching && data?.guardmes?.nodes.length === 0 && (
                    <TableRow className="last:border-none hover:bg-inherit">
                      <TableCell
                        className="text-center h-24"
                        colSpan={8}
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                  {!fetching &&
                    data?.guardmes?.nodes.map((item) => (
                      <TableRow
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                      >
                        <TableCell className="font-bold whitespace-nowrap">
                          <Link
                            className="hover:underline"
                            to={`/guardme/${item.id}`}
                          >
                            {item.shortId}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            <Avatar className="h-6 w-6">
                              <AvatarImage
                                src={item.member?.avatarUrl ?? undefined}
                              />
                              <AvatarFallback>
                                {item.member?.fullName.slice(0, 2)}
                              </AvatarFallback>
                            </Avatar>
                            <div className="text-sm">
                              {item.member?.displayName ??
                                item.member?.fullName}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <BatteryLevel value={item.batteryLevel} />
                        </TableCell>
                        <TableCell>
                          <GuardMeStatus status={item.status} />
                        </TableCell>
                        <TableCell>
                          <Datetime datetime={item.createdAt} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardFooter>
              <TablePagnination
                currentPage={controls.page}
                getSearchParameters={controls.getPageSearchParameters}
                pageSize={controls.pageSize}
                totalCount={data?.guardmes?.totalCount}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export { GuardMes };
