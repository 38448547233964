import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { graphql } from '@/gql';
import { Fragment, useState } from 'react';
import { Label, Pie, PieChart } from 'recharts';
import { useQuery } from 'urql';

const ActiveIncidentsGql = graphql(`
  query ActiveIncidentsGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      organizationMetric {
        incidentsActive
        incidentsOpen
        incidentsInProgress
        incidentsActiveHigh
        incidentsActiveLow
        incidentsActiveMedium
        incidentsActiveUrgent
      }
      incidents(
        condition: { status: { inProgress: true, closed: false, open: true } }
      ) {
        nodes {
          id
          incidentType {
            id
            name
          }
        }
      }
    }
  }
`);

type Props = {
  readonly organizationId: string;
};

enum View {
  PRIORITY = 'priority',
  STATUS = 'status',
  TYPE = 'type',
}

const ActiveIncidents = ({ organizationId }: Props) => {
  const [view, setView] = useState<View>(View.STATUS);

  const [{ data, fetching }] = useQuery({
    query: ActiveIncidentsGql,
    variables: {
      organizationId,
    },
  });

  let chartConfig: {
    [name: string]: {
      color?: string;
      label: string;
    };
  } = {};

  let incidentData: Array<{
    fill: string;
    key: string;
    label: string;
    value: number;
  }> = [];

  const chartColors = [
    'hsl(var(--chart-1))',
    'hsl(var(--chart-2))',
    'hsl(var(--chart-3))',
    'hsl(var(--chart-4))',
    'hsl(var(--chart-5))',
  ];

  if (view === View.TYPE) {
    chartConfig = {};
    const incidentTypeCount: Record<string, number> = {};

    for (const [index, node] of data?.organization?.incidents.nodes.entries() ??
      []) {
      if (node.incidentType) {
        const { id, name } = node.incidentType;
        // add the label to the chartConfig object
        chartConfig[id] = { color: chartColors[index % 5], label: name };

        // count the number of incidents for that type
        if (Object.hasOwn(incidentTypeCount, id)) {
          incidentTypeCount[id] += 1;
        } else {
          incidentTypeCount[id] = 1;
        }
      }
    }

    incidentData = Object.entries(incidentTypeCount)
      .map(([key, value]) => ({
        fill: `var(--color-${key})`,
        key,
        label: value.toLocaleString(),
        value,
      }))
      .sort((a, b) => (a.value > b.value ? -1 : 1));
  }

  if (view === View.STATUS) {
    chartConfig = {
      inprogress: { color: chartColors[1], label: 'In Progress' },
      open: { color: chartColors[0], label: 'Open' },
    };

    incidentData = [
      {
        fill: `var(--color-open)`,
        key: 'open',
        label:
          data?.organization?.organizationMetric?.incidentsOpen.toLocaleString(),
        value: Number.parseInt(
          data?.organization?.organizationMetric?.incidentsOpen,
          10,
        ),
      },
      {
        fill: `var(--color-inprogress)`,
        key: 'inprogress',
        label:
          data?.organization?.organizationMetric?.incidentsInProgress.toLocaleString(),
        value: Number.parseInt(
          data?.organization?.organizationMetric?.incidentsInProgress,
          10,
        ),
      },
    ];
  }

  if (view === View.PRIORITY) {
    chartConfig = {
      high: { color: chartColors[1], label: 'High' },
      low: { color: chartColors[3], label: 'Low' },
      medium: { color: chartColors[2], label: 'Medium' },
      urgent: { color: chartColors[0], label: 'Urgent' },
    };

    incidentData = [
      {
        fill: `var(--color-urgent)`,
        key: 'urgent',
        label:
          data?.organization?.organizationMetric?.incidentsActiveUrgent.toLocaleString(),
        value: Number.parseInt(
          data?.organization?.organizationMetric?.incidentsActiveUrgent,
          10,
        ),
      },
      {
        fill: `var(--color-high)`,
        key: 'high',
        label:
          data?.organization?.organizationMetric?.incidentsActiveHigh.toLocaleString(),
        value: Number.parseInt(
          data?.organization?.organizationMetric?.incidentsActiveHigh,
          10,
        ),
      },
      {
        fill: `var(--color-medium)`,
        key: 'medium',
        label:
          data?.organization?.organizationMetric?.incidentsActiveMedium.toLocaleString(),
        value: Number.parseInt(
          data?.organization?.organizationMetric?.incidentsActiveMedium,
          10,
        ),
      },
      {
        fill: `var(--color-low)`,
        key: 'low',
        label:
          data?.organization?.organizationMetric?.incidentsActiveLow.toLocaleString(),
        value: Number.parseInt(
          data?.organization?.organizationMetric?.incidentsActiveLow,
          10,
        ),
      },
    ];
  }

  return (
    <Card>
      <CardHeader className="p-4 pb-0">
        <CardTitle>Active Incidents</CardTitle>
        <CardDescription>
          There are{' '}
          {Number.parseInt(
            data?.organization?.organizationMetric?.incidentsOpen,
            10,
          )}{' '}
          open incidents that need review.
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        {!data && fetching && (
          <Skeleton className="mx-auto rounded-full my-[20px] h-[260px] w-[260px]" />
        )}
        {data?.organization?.organizationMetric && (
          <ChartContainer
            className="mx-auto aspect-square max-h-[300px]"
            config={chartConfig}
          >
            <PieChart>
              <ChartTooltip
                content={<ChartTooltipContent hideLabel />}
                cursor={false}
              />
              <Pie
                data={incidentData}
                dataKey="value"
                innerRadius={60}
                nameKey="key"
                paddingAngle={2}
                strokeWidth={1}
              >
                <Label
                  // eslint-disable-next-line react/no-unstable-nested-components
                  content={({ viewBox }) => {
                    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                      return (
                        <text
                          dominantBaseline="middle"
                          textAnchor="middle"
                          x={viewBox.cx}
                          y={viewBox.cy}
                        >
                          <tspan
                            className="fill-foreground text-3xl font-bold"
                            x={viewBox.cx}
                            y={viewBox.cy}
                          >
                            {data?.organization?.organizationMetric?.incidentsActive.toLocaleString()}
                          </tspan>
                          <tspan
                            className="fill-muted-foreground"
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                          >
                            Active
                          </tspan>
                        </text>
                      );
                    }

                    return null;
                  }}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
        )}

        <Tabs
          className="w-full"
          defaultValue="type"
          onValueChange={(value) => {
            setView(value as View);
          }}
          value={view}
        >
          <TabsList className="grid w-full grid-cols-3 px-1.25">
            <TabsTrigger value={View.PRIORITY}>Priority</TabsTrigger>
            <TabsTrigger value={View.STATUS}>Status</TabsTrigger>
            <TabsTrigger value={View.TYPE}>Type</TabsTrigger>
          </TabsList>
        </Tabs>
      </CardContent>

      <CardFooter className="flex flex-row border-t p-4">
        {fetching ? (
          <Skeleton className="h-[42px] w-full" />
        ) : (
          <div className="flex w-full items-center gap-2">
            {view === View.TYPE &&
              incidentData.slice(0, 3).map((item, index) => (
                <Fragment key={item.label}>
                  {Boolean(index) && (
                    <Separator
                      className="mx-2 h-10 w-px"
                      orientation="vertical"
                    />
                  )}
                  <div className="grid flex-1 auto-rows-min gap-0.5">
                    <div className="text-xs text-muted-foreground">
                      {chartConfig[item.key].label}
                    </div>
                    <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                      {item.value.toLocaleString()}
                    </div>
                  </div>
                </Fragment>
              ))}

            {view === View.STATUS && (
              <>
                <div className="grid flex-1 auto-rows-min gap-0.5">
                  <div className="text-xs text-muted-foreground">Open</div>
                  <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                    {Number.parseInt(
                      data?.organization?.organizationMetric?.incidentsOpen,
                      10,
                    ).toLocaleString()}
                  </div>
                </div>
                <Separator
                  className="mx-2 h-10 w-px"
                  orientation="vertical"
                />
                <div className="grid flex-1 auto-rows-min gap-0.5">
                  <div className="text-xs text-muted-foreground">
                    In Progress
                  </div>
                  <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                    {Number.parseInt(
                      data?.organization?.organizationMetric
                        ?.incidentsInProgress,
                      10,
                    ).toLocaleString()}
                  </div>
                </div>
              </>
            )}

            {view === View.PRIORITY && (
              <>
                <div className="grid flex-1 auto-rows-min gap-0.5">
                  <div className="text-xs text-muted-foreground">Urgent</div>
                  <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                    {Number.parseInt(
                      data?.organization?.organizationMetric
                        ?.incidentsActiveUrgent,
                      10,
                    ).toLocaleString()}
                  </div>
                </div>
                <Separator
                  className="mx-2 h-10 w-px"
                  orientation="vertical"
                />
                <div className="grid flex-1 auto-rows-min gap-0.5">
                  <div className="text-xs text-muted-foreground">High</div>
                  <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                    {Number.parseInt(
                      data?.organization?.organizationMetric
                        ?.incidentsActiveHigh,
                      10,
                    ).toLocaleString()}
                  </div>
                </div>
                <Separator
                  className="mx-2 h-10 w-px"
                  orientation="vertical"
                />
                <div className="grid flex-1 auto-rows-min gap-0.5">
                  <div className="text-xs text-muted-foreground">Medium</div>
                  <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                    {Number.parseInt(
                      data?.organization?.organizationMetric
                        ?.incidentsActiveMedium,
                      10,
                    ).toLocaleString()}
                  </div>
                </div>
                <Separator
                  className="mx-2 h-10 w-px"
                  orientation="vertical"
                />
                <div className="grid flex-1 auto-rows-min gap-0.5">
                  <div className="text-xs text-muted-foreground">Low</div>
                  <div className="flex items-baseline gap-1 text-2xl font-bold tabular-nums leading-none">
                    {Number.parseInt(
                      data?.organization?.organizationMetric
                        ?.incidentsActiveLow,
                      10,
                    ).toLocaleString()}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

export { ActiveIncidents };
