import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { ChartContainer } from '@/components/ui/chart';
import { graphql } from '@/gql';
import { percentChange } from '@/lib/percentChange';
import { daysFromStartOfWeek } from '@/lib/time';
import { Percent, TrendingDown, TrendingUp } from 'lucide-react';
import { Bar, BarChart, CartesianGrid, Rectangle, XAxis } from 'recharts';
import { useQuery } from 'urql';

const CheckInCountGql = graphql(`
  query CheckInCountGql($organizationId: String!, $days: Int!) {
    organization(id: $organizationId) {
      id
      organizationMetric {
        checkInsTotal
        checkInReportsTotal
        checkInsGraph(days: $days, timezone: "US/Eastern") {
          date
          count
        }
      }
    }
  }
`);

type Props = {
  readonly organizationId: string;
};

const CheckInCount = ({ organizationId }: Props) => {
  const days = daysFromStartOfWeek();

  const [{ data }] = useQuery({
    query: CheckInCountGql,
    variables: {
      days: days + 7,
      organizationId,
    },
  });

  const lastweek =
    data?.organization?.organizationMetric?.checkInsGraph?.slice(
      0,
      data?.organization?.organizationMetric?.checkInsGraph?.length - days,
    ) ?? [];

  const thisweek =
    data?.organization?.organizationMetric?.checkInsGraph?.slice(
      data?.organization?.organizationMetric?.checkInsGraph?.length - days,
    ) ?? [];

  const countThisWeek = thisweek.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.parseInt(currentValue?.count, 10),
    0,
  );

  const countLastWeek = lastweek.reduce(
    (accumulator, currentValue) =>
      accumulator + Number.parseInt(currentValue?.count, 10),
    0,
  );

  const change = percentChange(countLastWeek, countThisWeek);

  return (
    <Card>
      <CardHeader className="p-4 pb-0">
        <CardTitle>Check In</CardTitle>
        <CardDescription>
          There are{' '}
          {data?.organization?.organizationMetric?.checkInsTotal?.toLocaleString()}{' '}
          total Check Ins in this organization.
        </CardDescription>
      </CardHeader>
      <CardContent className="flex flex-row items-end gap-4 p-4">
        <div className="flex items-baseline gap-2 text-3xl font-bold tabular-nums leading-none">
          {countThisWeek}
          <span className="text-sm font-normal text-muted-foreground flex items-center">
            This Week ({change}
            <Percent className="h-3 w-3" />
            {change > 0 && <TrendingUp className="ml-1 h-4 w-4" />}
            {change < 0 && <TrendingDown className="ml-1 h-4 w-4" />})
          </span>
        </div>
        <ChartContainer
          className="ml-auto h-[32px] w-[128px]"
          config={{
            count: {
              color: 'hsl(var(--chart-1))',
              label: 'Count',
            },
          }}
        >
          <BarChart
            accessibilityLayer
            data={
              data?.organization?.organizationMetric?.checkInsGraph?.map(
                (item) => ({
                  count: Number.parseInt(item?.count, 10),
                  date: item?.date,
                }),
              ) ?? []
            }
            margin={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              axisLine={false}
              dataKey="date"
              hide
              tickLine={false}
              tickMargin={4}
            />
            <Bar
              activeBar={<Rectangle fillOpacity={0.8} />}
              activeIndex={6}
              dataKey="count"
              fill="var(--color-calories)"
              fillOpacity={0.2}
              radius={2}
            />
          </BarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export { CheckInCount };
