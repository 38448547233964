import GuardMePanicSfx from '@/assets/sounds/guardme-panic.mp3';
import GuardMeStartedSfx from '@/assets/sounds/guardme-started.mp3';
import { type DocumentType, graphql } from '@/gql';
import { GraphqlGuardmeEvent } from '@/gql/graphql';
import { formatDatetime } from '@/lib/time';
import { toast } from '@/lib/toast';
import { useAppStore } from '@/stores';
import { type Cache } from '@urql/exchange-graphcache';
import { Howl } from 'howler';
import { type NavigateFunction } from 'react-router-dom';
import { useSubscription } from 'urql';

const GuardMeUpdatesGql = graphql(`
  subscription GuardMeUpdatesGql($organizationId: String!) {
    guardmeUpdates(input: { organizationId: $organizationId }) {
      guardme {
        __typename
        id
        shortId
        status
        endsAt
        completedAt
        createdAt
        updatedAt
        isSafe
        isActive
        isExpired
        batteryLevel
        notes
        organizationId
        locationTimelineAsEncodedPolyline
        lastLocation {
          id
          createdAt
          coordinates {
            x
            y
          }
          geocode {
            id
            address
            plusCode
          }
        }
        organization {
          id
          name
        }
        memberId
        member {
          id
          fullName
          displayName
          avatarUrl
          phoneNumber
        }
        totalAttachments
        guardmeAttachments {
          totalCount
          nodes {
            id
          }
        }
        guardmeAuditLogs {
          nodes {
            id
          }
        }
      }
      event
      relatedIds
    }
  }
`);

const guardmeUpdatesResolver =
  (navigate: NavigateFunction) =>
  (
    result: DocumentType<typeof GuardMeUpdatesGql>,
    _args: never,
    cache: Cache,
  ) => {
    if (!result?.guardmeUpdates?.guardme) {
      // no guardme on object, do nothing
      return;
    }

    if (!result.guardmeUpdates.guardme.organization) {
      // unknown organization
      return;
    }

    const event = result.guardmeUpdates.event ?? 'updated';
    const guardmeId = result.guardmeUpdates.guardme.id;
    const shortId = result.guardmeUpdates.guardme.shortId;
    const organizationId = result.guardmeUpdates.guardme.organizationId;

    // navigate action
    const handleToastOnClick = () => {
      useAppStore.setState((state) => ({
        activeMembership:
          state.memberships.find((org) => org.id === organizationId) ??
          state.activeMembership,
      }));
      navigate(`/guardme/${guardmeId}`);
    };

    if (event === GraphqlGuardmeEvent.Started) {
      const sound = new Howl({
        src: GuardMeStartedSfx,
      });

      const soundId = sound.play();

      const memberName =
        result.guardmeUpdates.guardme.member?.displayName ??
        result.guardmeUpdates.guardme.member?.fullName ??
        'A member';
      const endsAt = formatDatetime({
        datetime: result.guardmeUpdates.guardme.endsAt,
        format: 'MMM dd, hh:mm aaa',
      });

      toast.info(`New GuardMe Session ${shortId}`, {
        action: {
          label: 'View',
          onClick: handleToastOnClick,
        },
        description: `${memberName} expects the session to end ${endsAt}.`,
      });

      setTimeout(() => {
        sound.stop(soundId);
      }, 3_000);

      // invalidate incident query results to cause refetch with new data
      for (const field of cache
        .inspectFields('Query')
        .filter((query) => query.fieldName === 'guardmes')) {
        cache.invalidate('Query', 'guardmes', field.arguments);
      }

      cache.invalidate('Query', 'activeGuardmes', { organizationId });
    }

    if (event === GraphqlGuardmeEvent.Expired) {
      const memberName =
        result.guardmeUpdates.guardme.member?.displayName ??
        result.guardmeUpdates.guardme.member?.fullName ??
        'A member';

      toast.warning(`GuardMe Session ${shortId} Expired`, {
        action: {
          label: 'View',
          onClick: handleToastOnClick,
        },
        description: `${memberName} may require assistance.`,
        duration: Number.POSITIVE_INFINITY,
      });

      cache.invalidate('Query', 'activeGuardmes', { organizationId });
    }

    if (event === GraphqlGuardmeEvent.Panic) {
      const sound = new Howl({
        src: GuardMePanicSfx,
      });

      const soundId = sound.play();

      const memberName =
        result.guardmeUpdates.guardme.member?.displayName ??
        result.guardmeUpdates.guardme.member?.fullName ??
        'A member';

      toast.warning(`GuardMe Session ${shortId} Requires Assistance!`, {
        action: {
          label: 'View',
          onClick: handleToastOnClick,
        },
        description: `${memberName} requested assistance.`,
        duration: Number.POSITIVE_INFINITY,
      });

      setTimeout(() => {
        sound.stop(soundId);
      }, 3_000);

      for (const field of cache
        .inspectFields('Query')
        .filter((query) => query.fieldName === 'guardmes')) {
        cache.invalidate('Query', 'guardmes', field.arguments);
      }

      cache.invalidate('Query', 'activeGuardmes', { organizationId });
    }

    if (event === GraphqlGuardmeEvent.Concluded) {
      const memberName =
        result.guardmeUpdates.guardme.member?.displayName ??
        result.guardmeUpdates.guardme.member?.fullName ??
        'A member';

      toast.info(`GuardMe Session ${shortId} Concluded`, {
        action: {
          label: 'View',
          onClick: handleToastOnClick,
        },
        description: `${memberName}'s GuardMe session concluded.`,
      });

      for (const field of cache
        .inspectFields('Query')
        .filter((query) => query.fieldName === 'guardmes')) {
        cache.invalidate('Query', 'guardmes', field.arguments);
      }

      cache.invalidate('Query', 'activeGuardmes', { organizationId });
    }

    if (event === GraphqlGuardmeEvent.AttachmentAdded) {
      const sound = new Howl({
        src: GuardMePanicSfx,
      });

      const soundId = sound.play();

      // const memberName =
      //   result.guardmeUpdates.guardme.member?.displayName ??
      //   result.guardmeUpdates.guardme.member?.fullName ??
      //   'A member';

      toast.info(`GuardMe Session ${shortId} Updated`, {
        action: {
          label: 'View',
          onClick: handleToastOnClick,
        },
        description: 'Attachment Added',
        id: `${guardmeId}-added-attachment`,
      });

      setTimeout(() => {
        sound.stop(soundId);
      }, 3_000);
    }
  };

type GuardMeUpdatesProps = { readonly organizationId: string };

const GuardMeUpdates = ({ organizationId }: GuardMeUpdatesProps) => {
  useSubscription({
    query: GuardMeUpdatesGql,
    variables: {
      organizationId,
    },
  });

  return null;
};

export { GuardMeUpdates, type GuardMeUpdatesProps, guardmeUpdatesResolver };
