import { generateAvatarUrl } from '../../utils/generateAvatarUrl';
import { type Size } from '../constants';
import { type HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const logoSizes: Record<Size, string> = {
  lg: 'h-12 w-12',
  md: 'h-9 w-9',
  sm: 'h-8 w-8',
  xl: 'h-14 w-14',
  xs: 'h-6 w-6',
} as const;

type LogoProps = HTMLAttributes<HTMLImageElement> & {
  name?: string;
  size?: keyof typeof logoSizes;
  src?: string;
};

const Logo = forwardRef<HTMLImageElement, LogoProps>((props, ref) => {
  const {
    className,
    name = 'Organization',
    size = 'md',
    src,
    ...extra
  } = props;

  const source = generateAvatarUrl(name, 1);

  return (
    <img
      {...extra}
      alt={name ?? 'Logo'}
      aria-label="Logo"
      className={twMerge('max-w-fit', logoSizes[size], className)}
      ref={ref}
      src={src || source}
    />
  );
});

export { Logo, type LogoProps };
