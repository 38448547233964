import { type Size } from '../constants';
import { Avatar, type AvatarProps } from './Avatar';
import { Children, type HTMLAttributes, type ReactElement } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const avatarSizes: Record<Size, string> = {
  lg: 'h-12 w-12',
  md: 'h-9 w-9',
  sm: 'h-8 w-8',
  xl: 'h-14 w-14',
  xs: 'h-6 w-6',
} as const;

const textSizes: Record<Size, string> = {
  lg: 'text-lg',
  md: 'text-md',
  sm: 'text-sm',
  xl: 'text-xl',
  xs: 'text-xs',
} as const;

type AvatarGroupProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  children: Array<ReactElement<AvatarProps>> | ReactElement<AvatarProps>;
  max?: number;
  size?: Size;
};

const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(
  (props, ref) => {
    const { children, className, max = 5, size = 'md', ...extra } = props;

    const avatars = Children.map(children, (child) => {
      return {
        name: child.props.name,
        src: child.props.src,
      };
    });

    const displayAvatars = avatars.slice(0, max);
    const remaningAvatars = avatars.length > max ? avatars.slice(max) : [];

    return (
      <div
        {...extra}
        className={twMerge('flex -space-x-1 relative z-0', className)}
        ref={ref}
      >
        {displayAvatars.map((item) => (
          <Avatar
            key={item.name}
            name={item.name}
            size={size}
            src={item.src}
          />
        ))}
        {remaningAvatars.length > 0 && (
          <span
            className={twMerge(
              'inline-flex items-center justify-center rounded-full bg-gray-600',
              avatarSizes[size],
              className,
            )}
            ref={ref}
          >
            <span
              className={twMerge(
                'font-medium text-white leading-none',
                textSizes[size],
              )}
            >
              {`${remaningAvatars.length}+`}
            </span>
          </span>
        )}
      </div>
    );
  },
);

export { AvatarGroup, type AvatarGroupProps };
