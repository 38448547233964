import { AdministrationHeader } from './AdministrationHeader';
import { ClientError } from '@/components/Error/ClientError';
import { ErrorBoundaryFallback } from '@/features/Misc';
import { useAppStore } from '@/stores';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

const AdministrationLayout = () => {
  const isGlobalAdmin = useAppStore((state) => state.isGlobalAdmin);

  const { pathname } = useLocation();

  if (!isGlobalAdmin) {
    return (
      <div className="lg:ml-16 flex flex-col lg:flex-row grow">
        <div className="pt-8 lg:pl-6">
          <h2 className="text-2xl font-semibold mx-2 mb-2 lg:mx-0 lg:mb-7">
            Adminstration
          </h2>
        </div>
        <ClientError code={403} />
      </div>
    );
  }

  return (
    <div className="h-full max-h-screen">
      <ScrollRestoration />
      <AdministrationHeader />

      <main className="h-[calc(100vh-56px)]">
        <ErrorBoundary
          fallback={ErrorBoundaryFallback}
          key={pathname}
        >
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};

export { AdministrationLayout };
