import { Empty } from '@/components/Empty';
import { Loader } from '@/components/Loader';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from '@/components/ui/pagination';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { IncidentPriority } from '@/features/Incidents/components/IncidentPriority';
import { IncidentStatus } from '@/features/Incidents/components/IncidentStatus';
import { graphql } from '@/gql';
import { ChevronLeft, ChevronRight, Plus, SquareActivity } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MyIncidentsGql = graphql(`
  query MyIncidentsGql($after: Cursor, $first: Int!) {
    currentUserIncidents(after: $after, first: $first) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          subject
          shortId
          status
          priority
          incidentType {
            id
            name
            iconSvg
          }
          organization {
            id
            name
            logoUrl
          }
        }
      }
    }
  }
`);

const MyIncidents = () => {
  const [after, setAfter] = useState<Array<string | null>>([null]);

  const [{ data, error, fetching }] = useQuery({
    query: MyIncidentsGql,
    variables: {
      after: after[after.length - 1],
      first: 6,
    },
  });

  const handleNextPage = () => {
    if (data?.currentUserIncidents?.pageInfo.endCursor) {
      setAfter((previous) => [
        ...previous,
        data?.currentUserIncidents?.pageInfo.endCursor,
      ]);
    }
  };

  const handlePreviousPage = () => {
    setAfter((previous) => previous.slice(0, previous.length - 1));
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="p-4">
        <CardTitle className="text-lg flex flex-col md:flex-row md:items-center gap-2 flex lg:justify-between">
          My Incidents
          <Link to="/incidents/new">
            <Button variant="secondary">
              <Plus className="h-4 w-4" /> New
            </Button>
          </Link>
        </CardTitle>
      </CardHeader>
      <Loader loadingError={error}>
        <CardContent className="p-0">
          {fetching &&
            [...Array.from({ length: 6 }).keys()].map((item) => (
              <div
                className="flex items-center space-y-1 px-4 py-2"
                key={item}
              >
                <Skeleton className="h-12 w-full" />
              </div>
            ))}

          {!fetching && data?.currentUserIncidents?.edges.length === 0 && (
            <Empty className="flex flex-col items-center justify-center h-full gap-1">
              <SquareActivity className="w-12 h-12 text-muted-foreground/75" />
              <p className="">No Recent Activity</p>
            </Empty>
          )}
          <div className="divide-y">
            {!fetching &&
              data?.currentUserIncidents?.edges.map(({ node: item }) => (
                <div
                  className="px-4 py-2 flex items-center"
                  key={item.id}
                >
                  <Badge
                    className="h-10 w-10 flex items-center rounded-lg mr-2"
                    variant="secondary"
                  >
                    <img
                      alt={item.incidentType?.name}
                      className="h-6 w-6"
                      src={`data:image/svg+xml;base64,${item.incidentType?.iconSvg}`}
                    />
                    <span className="sr-only">{item.incidentType?.name}</span>
                  </Badge>

                  <div className="w-full">
                    <div>
                      <Link
                        className="text-sm font-bold hover:underline"
                        to={`/incidents/${item.id}`}
                      >
                        {item.subject || '--No Subject--'}
                      </Link>
                    </div>

                    <div className="flex items-center text-sm gap-2 text-muted-foreground">
                      {item.shortId}
                      <Separator
                        className="mx-1 h-4 w-px"
                        orientation="vertical"
                      />
                      {item.organization?.name}
                      <Separator
                        className="mx-1 h-4 w-px"
                        orientation="vertical"
                      />
                      <IncidentStatus status={item.status} />
                      <Separator
                        className="mx-1 h-4 w-px"
                        orientation="vertical"
                      />
                      <IncidentPriority priority={item.priority} />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </CardContent>
        <CardFooter className="flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
          <div className="text-xs text-muted-foreground">
            {data?.currentUserIncidents?.totalCount.toLocaleString()} Incidents
          </div>
          <Pagination className="ml-auto mr-0 w-auto">
            <PaginationContent>
              <PaginationItem>
                <Button
                  className="h-6 w-6"
                  disabled={
                    !data?.currentUserIncidents?.pageInfo.hasPreviousPage
                  }
                  onClick={handlePreviousPage}
                  size="icon"
                  variant="outline"
                >
                  <ChevronLeft className="h-3.5 w-3.5" />
                  <span className="sr-only">Previous Order</span>
                </Button>
              </PaginationItem>
              <PaginationItem>
                <Button
                  className="h-6 w-6"
                  disabled={!data?.currentUserIncidents?.pageInfo.hasNextPage}
                  onClick={handleNextPage}
                  size="icon"
                  variant="outline"
                >
                  <ChevronRight className="h-3.5 w-3.5" />
                  <span className="sr-only">Next Order</span>
                </Button>
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </CardFooter>
      </Loader>
    </Card>
  );
};

export { MyIncidents };
