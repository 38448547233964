import { generateAvatarUrl } from '../../utils/generateAvatarUrl';
import { type Size } from '../constants';
import { type HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

const avatarSizes: Record<Size, string> = {
  lg: 'h-12 w-12',
  md: 'h-9 w-9',
  sm: 'h-8 w-8',
  xl: 'h-14 w-14',
  xs: 'h-6 w-6',
} as const;

type AvatarProps = HTMLAttributes<HTMLImageElement> & {
  name?: string;
  size?: Size;
  src?: string | null;
};

const Avatar = forwardRef<HTMLImageElement, AvatarProps>((props, ref) => {
  const { className, name = 'User', size = 'md', src, ...extra } = props;

  const source = generateAvatarUrl(name, 2);

  // eslint-disable-next-line no-negated-condition
  const imgSource = !src ? source : src;

  return (
    <img
      {...extra}
      alt={name ?? 'Avatar'}
      aria-label="Avatar"
      className={twMerge(
        'max-w-fit rounded-full ring-2 ring-white dark:ring-gray-800',
        avatarSizes[size],
        className,
      )}
      ref={ref}
      src={imgSource}
    />
  );
});

export { Avatar, type AvatarProps };
