import { Datetime } from '@/components/Datetime';
import { Empty } from '@/components/Empty';
import { Loader } from '@/components/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from '@/components/ui/pagination';
import { Skeleton } from '@/components/ui/skeleton';
import { graphql } from '@/gql';
import { GuardmeStatus, type Member } from '@/gql/graphql';
import { toDatetime } from '@/lib/time';
import { sub } from 'date-fns/sub';
import {
  ChevronLeft,
  ChevronRight,
  Dot,
  Flag,
  MapPin,
  ShieldCheck,
  SquareActivity,
} from 'lucide-react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const RecentGql = graphql(`
  query RecentGql($gte: Datetime!) {
    guardmes(orderBy: ID_DESC, condition: { createdAt: { gte: $gte } }) {
      nodes {
        id
        shortId
        organization {
          id
          name
        }
        member {
          id
          avatarUrl
          displayName
          fullName
        }
        status
        updatedAt
      }
    }
    checkIns(orderBy: ID_DESC, condition: { createdAt: { gte: $gte } }) {
      nodes {
        id
        shortId
        organization {
          id
          name
        }
        member {
          id
          avatarUrl
          fullName
          displayName
        }
        createdAt
      }
    }
    incidents(orderBy: ID_DESC, condition: { createdAt: { gte: $gte } }) {
      nodes {
        id
        shortId
        subject
        updatedAt
        organization {
          id
          name
        }
        incidentType {
          id
          name
        }
        contactMember {
          id
          avatarUrl
          displayName
          fullName
        }
      }
    }
  }
`);

type RecentEntry = {
  icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  id: string;
  location: string;
  member?: Pick<Member, 'id' | 'avatarUrl' | 'fullName' | 'displayName'> | null;
  timestamp: Date;
  title: string;
  to: string;
  tooltip: string;
};

const Recent = () => {
  const first = 6;
  const [offset, setOffset] = useState(0);
  const cutoff = useMemo(() => sub(Date.now(), { days: 7 }), []);

  const [{ data, error, fetching }] = useQuery({
    query: RecentGql,
    variables: {
      gte: cutoff,
    },
  });

  // eslint-disable-next-line complexity
  const recentItems = useMemo(() => {
    const items: RecentEntry[] = [];

    for (const guardme of data?.guardmes?.nodes ?? []) {
      let title = '';
      if (guardme.status === GuardmeStatus.Concluded) {
        title = `${
          guardme.member?.displayName ?? guardme.member?.fullName
        } concluded a GuardMe`;
      }

      if (guardme.status === GuardmeStatus.Expired) {
        title = `GuardMe for ${
          guardme.member?.displayName ?? guardme.member?.fullName
        } has expired`;
      }

      if (guardme.status === GuardmeStatus.InPanic) {
        title = `${
          guardme.member?.displayName ?? guardme.member?.fullName
        } requires immediate help`;
      }

      if (guardme.status === GuardmeStatus.InProgress) {
        title = `GuardMe for ${
          guardme.member?.displayName ?? guardme.member?.fullName
        } in progress`;
      }

      items.push({
        icon: ShieldCheck,
        id: guardme.id,
        location: 'in GuardMe Sessions',
        member: guardme?.member,
        timestamp: toDatetime(guardme.updatedAt),
        title,
        to: `/guardme/${guardme.id}`,
        tooltip: `${guardme.organization?.name} / GuardMe / ${guardme.shortId}`,
      });
    }

    for (const checkIn of data?.checkIns?.nodes ?? []) {
      items.push({
        icon: MapPin,
        id: checkIn.id,
        location: "in Check In's",
        member: checkIn?.member,
        timestamp: toDatetime(checkIn.createdAt),
        title: `${
          checkIn.member?.displayName ?? checkIn.member?.fullName
        } checked in`,
        to: `/check-in/${checkIn.id}`,
        tooltip: `${checkIn.organization?.name} / Check-In / ${checkIn.shortId}`,
      });
    }

    for (const incident of data?.incidents?.nodes ?? []) {
      items.push({
        icon: Flag,
        id: incident.id,
        location: 'in Incidents',
        member: incident?.contactMember,
        timestamp: toDatetime(incident.updatedAt),
        title: `Submitted an Incident - ${incident.incidentType?.name}`,
        to: `/incidents/${incident.id}`,
        tooltip: `${incident.organization?.name} / Incidents / ${incident.shortId}`,
      });
    }

    items.sort((a, b) =>
      a.timestamp.valueOf() < b.timestamp.valueOf() ? 1 : -1,
    );

    return items.slice(offset, Math.min(offset + first, items.length));
  }, [data, offset]);

  const handleNextPage = () => {
    setOffset((previous) => Math.min(previous + first, recentItems.length));
  };

  const handlePreviousPage = () => {
    setOffset((previous) => Math.max(previous - first, recentItems.length));
  };

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="p-4">
        <CardTitle className="text-lg">Recent Activity</CardTitle>
      </CardHeader>
      <CardContent className="grow grid gap-4">
        <Loader loadingError={error}>
          {fetching &&
            [...Array.from({ length: 6 }).keys()].map((item) => (
              <div
                className="flex items-center space-y-1"
                key={item}
              >
                <Skeleton className="h-9 w-9 rounded-full" />
                <Skeleton className="ml-2 h-9 w-64" />
                <Skeleton className="ml-auto w-16 h-9" />
              </div>
            ))}

          {!fetching && recentItems.length === 0 && (
            <Empty className="flex flex-col items-center justify-center h-full gap-1">
              <SquareActivity className="w-12 h-12 text-muted-foreground/75" />
              <p className="">No Recent Activity</p>
            </Empty>
          )}

          {!fetching &&
            recentItems.map((item) => (
              <div
                className="flex items-center gap-4"
                key={item.id}
              >
                <Avatar className="hidden h-9 w-9 sm:flex">
                  <AvatarImage
                    alt="Avatar"
                    src={item.member?.avatarUrl ?? undefined}
                  />
                  <AvatarFallback>OM</AvatarFallback>
                </Avatar>
                <div className="grid gap-1">
                  <p className="text-sm font-medium leading-none flex items-center">
                    {item.member?.displayName ?? item.member?.fullName}
                    <Dot className="h-4 w-4" />
                    <Datetime
                      className="font-normal text-sm text-muted-foreground text-ellipsis"
                      datetime={item.timestamp}
                      variant="distance"
                    />
                  </p>
                  <p className="text-sm text-muted-foreground">{item.title}</p>
                </div>
                <div className="ml-auto font-medium">
                  <Link to={item.to}>
                    <Button variant="secondary">More</Button>
                  </Link>
                </div>
              </div>
            ))}
        </Loader>
      </CardContent>
      <CardFooter className="flex flex-row items-center justify-between border-t bg-muted/50 px-6 py-3">
        <div className="text-xs text-muted-foreground">
          {recentItems.length.toLocaleString()} Events
        </div>
        <Pagination className="ml-auto mr-0 w-auto">
          <PaginationContent>
            <PaginationItem>
              <Button
                className="h-6 w-6"
                disabled={recentItems.length > offset}
                onClick={handlePreviousPage}
                size="icon"
                variant="outline"
              >
                <ChevronLeft className="h-3.5 w-3.5" />
                <span className="sr-only">Previous Order</span>
              </Button>
            </PaginationItem>
            <PaginationItem>
              <Button
                className="h-6 w-6"
                disabled={offset === 0}
                onClick={handleNextPage}
                size="icon"
                variant="outline"
              >
                <ChevronRight className="h-3.5 w-3.5" />
                <span className="sr-only">Next Order</span>
              </Button>
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </CardFooter>
    </Card>
  );
};

export { Recent };
